
import { useEffect } from "react";

const useLogin = (user, history) => useEffect(() => {
  if (!user || !user.apiToken) {
    history.push("/login");
  }
}, [user]);

export default useLogin;
