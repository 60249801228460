import React from "react";
import { Provider } from "react-redux";
import configureStore from "@apparts/redux";

import defaultStore from "./defaultStore";
import venue from "./venue";
import orders from "./orders";
import loading from "./loading";
import waittime from "./waittime";
import { loginStore } from "@apparts/login";

// eslint-disable-next-line
const { store, persistor, types } = configureStore({
  defaultStore,
  venue,
  orders,
  loading,
  waittime,
  ...loginStore,
});

const withStore = (app) => <Provider store={store}>{app}</Provider>;
export { persistor, withStore };
export default store;
