// import {* as types} from './actions'

// console.error(venueConfigPojo);

const INITIAL_STATE = [];

const reducer = (types) => (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SAVE_ORDERS.name:
      return action.orders;
    case types.RESET.name:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
