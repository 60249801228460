import { get } from "util/api";
import { getVenueId } from "lenses/venueId";

export const saveOrders = (orders) => ({
  type: "SAVE_ORDERS",
  orders,
});

export const setLoading = (state) => ({
  type: "SET_ORDERS_LOADING",
  state,
});

export const updateOrders = (next) => async (dispatch, getState) => {
  const { user, orders } = getState();
  const venueId = getVenueId(user);
  try {
    dispatch(setLoading(true));
    const response = await get("venue/$1/orders/$2", [
      venueId,
      orders.map(({ id }) => id),
    ])
      .auth(user)
      .on(
        { status: 403, error: "You don't have the rights to retrieve this." },
        () => {
          alert("You can not access this data. Please contact support.");
        }
      );
    dispatch(
      saveOrders(
        response.sort(
          ({ created: created1 }, { created: created2 }) => created2 - created1
        )
      )
    );
    dispatch(setLoading(false));
  } catch (e) {
    e && alert(e);
    dispatch(setLoading(false));
  }
  next();
};

export const loadOrders =
  (page = 0) =>
  async (dispatch, getState) => {
    const { user } = getState();
    const venueId = getVenueId(user);
    try {
      dispatch(setLoading(true));
      const response = await get("venue/$1/orders", [venueId])
        .query({
          limit: 50,
          offset: page * 50,
          order: [{ key: "created", dir: "DESC" }],
        })
        .auth(user)
        .on(
          { status: 403, error: "You don't have the rights to retrieve this." },
          () => {
            alert("You can not access this data. Please contact support.");
          }
        );
      dispatch(saveOrders(response));
      dispatch(setLoading(false));
    } catch (e) {
      e && alert(e);
      dispatch(setLoading(false));
    }
  };

export default [setLoading().type, saveOrders().type];
