import { get } from "util/api";
import { getVenueId } from "lenses/venueId";

export const setLoading = (state) => ({
  type: "SET_VENUE_LOADING",
  state,
});

export const saveVenue = (venue) => ({
  type: "SET_VENUE",
  venue,
});

export const loadVenue = () => async (dispatch, getState) => {
  const { user } = getState();
  const venueId = getVenueId(user);
  try {
    dispatch(setLoading(true));

    const [response] = await get("venue/$1", [[venueId]])
      .auth(user)
      .on(
        { status: 403, error: "You don't have the rights to retrieve this." },
        () => {
          alert("You can not access this data. Please contact support.");
        }
      );
    dispatch(saveVenue(response));
    dispatch(setLoading(false));
  } catch (e) {
    e && alert(e);
    dispatch(setLoading(false));
  }
};

export default [setLoading().type, saveVenue().type];
