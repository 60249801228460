// import {* as types} from './actions'

// console.error(venueConfigPojo);

const INITIAL_STATE = {};

const reducer = (types) => (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.SET_ORDERS_LOADING.name:
      return { ...state, orders: action.state };
    case types.SET_VENUE_LOADING.name:
      return { ...state, venue: action.state };
    case types.SET_WAITTIME_LOADING.name:
      return { ...state, waittime: action.state };
    case types.RESET.name:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
