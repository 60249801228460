import { useApi, Token, Request } from "@apparts/api";

let dev = false;

try {
  /* eslint-disable-next-line no-undef */
  if (process.env.NODE_ENV === "development") {
    dev = true;
  }
} catch (e) {
  dev = false;
}

const URL = dev
  ? "http://localhost:3000/v/"
  : location.host === "dev-venue.dineup.app"
  ? "https://1tcqiclktf.execute-api.eu-central-1.amazonaws.com/dev/v/"
  : "https://api.dineup.app/v/";

class MyToken extends Token {
  constructor(user) {
    super(user);

    // Tell Token where to find the users api token, should you
    // already have one
    this._apiToken = user.apiToken;
  }

  async renewAPIToken(user) {
    // Tell Token how to renew the API Token
    const apiToken = await get("venue/user/apiToken").authPW(
      user.email,
      user.loginToken
    );
    return apiToken;
  }

  static getUserKey(user = {}) {
    // Tell Token how to identify users
    return user.email;
  }
}

class MyRequest extends Request {
  getURL(apiVersion) {
    // Tell Request what the URL prefix is
    return URL + apiVersion;
  }

  getAPIVersion() {
    // Tell Request what the default APIVersion is
    return 1;
  }

  online() {
    // Will be called, when a network-call succeded
  }

  auth(user) {
    if (user && (user.email || user.apiToken)) {
      this._auth = MyToken.getAPIToken(user);
    }
    return this;
  }

  async middleware() {
    this.on(0, () => {
      // on offline
    });
    this.on(401, () => {
      window.location.href = "/login?force=true";
    });
  }
}

const { get, put, patch, post, del } = useApi(MyRequest);
export { get, put, patch, post, del };
