import React from "react";
import PropTypes from "prop-types";

DuCounter.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
};

export function DuCounter({ min = 0, max = Infinity, onChange, value = "" }) {
  return (
    <div className="custom-number-input h-12 w-32">
      <div className="flex flex-row h-12 w-full rounded-lg relative bg-transparent">
        <button
          data-action="decrement"
          className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
          onClick={() => onChange(Math.max(min, value - 1))}
        >
          <span className="m-auto text-2xl font-thin">−</span>
        </button>

        <div className="flex justify-center outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none">
          {value}
        </div>
        <button
          data-action="increment"
          className="flex justify-center content-center bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
          onClick={() => onChange(Math.min(max, value + 1))}
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
}
