import React from "react";
import PropTypes from "prop-types";

export function DuActivityIndicator({ className, local }) {
  return (
    <div
      className={
        (local
          ? "relative"
          : "fixed right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ") +
        +className
      }
    >
      <div
        style={{ borderTopColor: "transparent" }}
        className={
          local
            ? "border-solid animate-spin  rounded-full border-blue-400 border-2 h-8 w-8"
            : "border-solid animate-spin  rounded-full border-blue-400 border-8 h-64 w-64"
        }
      ></div>
    </div>
  );
}

DuActivityIndicator.propTypes = {
  className: PropTypes.string,
  local: PropTypes.bool,
};
