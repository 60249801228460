import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateOrders } from "redux/orders/actions";

import { DuButton } from "components/DuButton/DuButton";

import { statusColor, getVenueStati } from "lenses/status";

import { post } from "util/api";

import { dateToText } from "util/index.js";

const Status = ({ order, user, updateOrders, venue }) => {
  const statiButtons = venue.allowedOrderStates
    .filter(({ venue }) => venue === "write")
    .map(({ name }) => name);
  const showStati = getVenueStati(order, venue);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const saveStatus = async (status) => {
    try {
      setLoading(true);
      await post("venue/$1/order/$2/state", [order.venueId, order.id])
        .data({ state: status, message })
        .auth(user);
      setLoading(false);
      setMessage("");
      updateOrders();
    } catch (e) {
      e && alert(e);
      setLoading(false);
    }
  };

  return (
    <div className="bg-white border border-gray-300 rounded-md overflow-hidden ">
      <div className="px-4 bg-white py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Status</h3>
      </div>

      <div className=" flex-1 px-4 pt-6">
        <label htmlFor="message" className="sr-only">
          Nachricht
        </label>
        <input
          value={message}
          onChange={({ target: { value } }) => setMessage(value)}
          type="text"
          name="message"
          id="message"
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-16"
          placeholder="Nachricht"
        />
      </div>
      <div className="py-2 flex items-center justify-between border-b">
        {statiButtons.map((status) => (
          <DuButton
            key={status}
            disabled={loading}
            loading={loading}
            label={status}
            className={"h-16 m-4 "}
            bg={statusColor(status)}
            onClick={() => saveStatus(status)}
          />
        ))}
      </div>
      <ul className="px-4 pt-2">
        {showStati.map(({ state, id, ...rest }) => (
          <li key={id} className=" py-4">
            <p
              className={
                "p-1 mb-1 rounded-md text-sm font-medium truncate " +
                statusColor(state)
              }
            >
              {state}
            </p>
            {rest.message && (
              <p className="pb-2 text-sm font-medium text-gray-900 truncate">
                {rest.message}
              </p>
            )}
            <p className="flex items-center text-sm text-gray-500">
              {dateToText(rest.created)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

Status.propTypes = {
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateOrders: PropTypes.func.isRequired,
  venue: PropTypes.object.isRequired,
};

export default connect(({ user, venue }) => ({ user, venue }), {
  updateOrders,
})(Status);
