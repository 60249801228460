import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

import DayReceip from "./DayReceip";
import GenerateQR from "./GenerateQR";

const More = ({ venue = {}, user }) => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="max-w-3xl mx-auto">
      <DayReceip venue={venue} user={user} />
      <GenerateQR venue={venue} user={user} />

      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Account
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <Link
            to={"/login?logout=true"}
            className="inline-flex h-12 items-center px-4 py-2 border
border-transparent text-sm font-medium rounded-md shadow-sm
hover:bg-indigo-700 focus:outline-none focus:ring-2
focus:ring-offset-2 focus:ring-indigo-500 text-white bg-indigo-600"
          >
            Logout
          </Link>
        </div>
      </div>
    </div>
  </div>
);
More.propTypes = {
  venue: PropTypes.object,
  user: PropTypes.object,
};
export default connect(({ venue, user }) => ({ venue, user }))(More);
