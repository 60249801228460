import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLogin } from "@apparts/login";
import * as components from "@apparts/web-components";
import { useLocation, useHistory, Link } from "react-router-dom";
import { resetStore } from "redux/defaultStore/actions";

// Import your configured @apparts/api here:
import * as api from "util/api";

const LoginScreen = ({ resetStore }) => {
  const { search } = useLocation(),
    logout = new URLSearchParams(search).get("logout"),
    history = useHistory();

  const Login = useLogin({
    api,
    apiPrefix: "venue/user",
    components: { ...components, Link },
  });
  return (
    <div
      style={{
        maxWidth: 600,
        margin: "auto",
        padding: 20,
        marginTop: 100,
      }}
    >
      <Login
        pwForgottenUrl="/requestpwreset"
        onLogin={() => history.push("/active-orders")}
        onLogout={() => {
          resetStore();
          history.push("/login");
        }}
        logMeOut={logout}
      />
    </div>
  );
};

LoginScreen.propTypes = {
  resetStore: PropTypes.func.isRequired,
};

export default connect(() => {}, { resetStore })(LoginScreen);
