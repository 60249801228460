import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { post } from "util/api";

import { DuButton } from "components/DuButton/DuButton";
import { DuFormikInput } from "components/DuFormikInput/DuFormikInput";

import QRCode from "qrcode";

const GenerateQR = ({ venue, user }) => {
  const [loading, setLoading] = useState(false);

  const generateQr = async ({ table }, actions) => {
    setLoading(true);

    try {
      const qrCodeId = await post("venue/$1/qrcode", [venue.id])
        .data({ table })
        .auth(user)
        .on(
          { status: 412, error: "Could not create item because it exists" },
          () => {
            alert(
              "Ein Fehler ist aufgetreten, bitte versuchen Sie es nocheinmal."
            );
          }
        );
      const url = "https://dineup.app/code/" + qrCodeId;
      const qrCode = await QRCode.toDataURL(url, {
        renderOpts: { width: 500 },
      });

      const link = document.createElement("a");
      link.download = `qr-code${table ? "-" + table : ""}.png`;
      link.href = qrCode.replace("image/png", "image/octet-stream");
      link.click();

      actions.setSubmitting(false);
    } catch (e) {
      e && alert(e);
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={{ table: "" }}
      validationSchema={Yup.object({
        table: Yup.string(),
      })}
      onSubmit={generateQr}
    >
      <Form>
        <div className="my-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              QR-Code generieren
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className=" flex justify-center ">
              <DuFormikInput
                label="Tischbezeichnung"
                name="table"
                description="Diese Tischbezeichnung wird in der Bestellung für das Gastropersonal sichtbar sein"
                cornerHint="optional"
                containerClassName="max-w-xs"
              />
            </div>
            <div className="mt-4 flex justify-end">
              <DuButton type="submit" loading={loading} label="Generieren" />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
GenerateQR.propTypes = {
  venue: PropTypes.object,
  user: PropTypes.object,
};

export default GenerateQR;
