import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { post } from "util/api";

import { DuButton } from "components/DuButton/DuButton";
import { DuFormikInput } from "components/DuFormikInput/DuFormikInput";
import { DuFormikSelect } from "components/DuFormikSelect/DuFormikSelect";

const DayReceip = ({ venue, user }) => {
  const [loading, setLoading] = useState(false);

  const sendReceip = async ({ email, day }, actions) => {
    if (!email || !day) {
      return;
    }

    setLoading(true);
    try {
      await post("venue/$1/dayreceipt", [venue.id])
        .data({ email, day: parseInt(day) })
        .auth(user);
      alert("E-Mail erfolgreich verschickt.");
      actions.setSubmitting(false);
    } catch (e) {
      e && alert(e);
    }
    setLoading(false);
  };

  const now = Date.now();

  return (
    <Formik
      initialValues={{ email: "", day: now }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Bitte geben Sie eine gültige E-Mail-Adresse an.")
          .required("Bitte geben Sie eine E-Mail-Adresse an."),
        day: Yup.string().required("Bitte geben Sie den gewünschten Tag an."),
      })}
      onSubmit={sendReceip}
    >
      <Form>
        <div className="my-4 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
          <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Tagesergebniss
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className=" flex flex-col items-start sm:flex-row">
              <DuFormikInput
                label="Email"
                name="email"
                description="Wir senden die Tagesübersicht an diese E-Mail-Adresse"
              />
              <DuFormikSelect
                name="day"
                label="Tag"
                description="Wir schicken das Tagesergebniss für den angegebenen Tag"
                containerClassName="mt-4 sm:ml-4 sm:mt-0"
              >
                {[0, 1, 2, 3, 4, 5, 6, 7].map((day) => (
                  <option key={day} value={now - 1000 * 60 * 60 * 24 * day}>
                    {new Date(
                      now - 1000 * 60 * 60 * 24 * day
                    ).toLocaleDateString(venue.defaultLanguage)}
                  </option>
                ))}
              </DuFormikSelect>
            </div>
            <div className="mt-4 flex justify-end">
              <DuButton type="submit" loading={loading} label="Senden" />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
DayReceip.propTypes = {
  venue: PropTypes.object,
  user: PropTypes.object,
};

export default DayReceip;
