const defaultState = true;

const reducer =
  (types) =>
  (state = defaultState, action = {}) => {
    switch (action.type) {
      case types.SET_NETWORK.name:
        return action.state;
      default:
        return state;
    }
  };

export default reducer;
