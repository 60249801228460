import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const DuFormikSelect = ({
  label,
  name,
  description,
  children,
  containerClassName,
  ...props
}) => (
  <div className={containerClassName}>
    <label
      htmlFor="location"
      className="block text-sm font-medium text-gray-700"
    >
      {label}
    </label>
    <Field
      as="select"
      id={name}
      name={name}
      className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      {...props}
    >
      {children}
    </Field>
    {description && (
      <p className="mt-2 text-sm text-gray-500" id={name + "-description"}>
        {description}
      </p>
    )}
  </div>
);
DuFormikSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  containerClassName: PropTypes.string,
};

export { DuFormikSelect };
