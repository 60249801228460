import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Orders from "views/Orders";
import Login from "views/Login";
import ResetPassword from "views/ResetPassword";
import RequestPWReset from "views/RequestPasswordReset";
import Menu from "views/Menu";
import More from "views/More";
import AppHeader from "view-shells/AppHeader/AppHeader";

export function AppContentPane() {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/active-orders" />
        </Route>

        <Route path="/login">
          <Login />
        </Route>

        <Route path="/resetpw">
          <ResetPassword />
        </Route>

        <Route path="/requestpwreset">
          <RequestPWReset />
        </Route>

        <AppHeader className="sticky">
          <Route path="/orders">
            <Orders />
          </Route>
          <Route path="/active-orders">
            <Orders active />
          </Route>
          <Route path="/more">
            <More />
          </Route>
          <Route path="/menu">
            <Menu />
          </Route>
        </AppHeader>
      </Switch>
    </div>
  );
}
