import { getUserDataFromApiToken } from "@apparts/login";

const getVenueId = (user) => {
  if (user) {
    return getUserDataFromApiToken(user).venueId;
  }
  return null;
};

export { getVenueId };
