import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateOrders } from "redux/orders/actions";

import { DuActivityIndicator } from "components/DuActivityIndicator/DuActivityIndicator";
import { DuButton } from "components/DuButton/DuButton";

import { orderWasRefunded } from "lenses/status";

import { del } from "util/api";

const Refund = ({
  order,
  user,
  updateOrders,
  loading: { orders: ordersLoading },
}) => {
  const [loading, setLoading] = useState(false);
  const requestRefund = async () => {
    setLoading(true);
    try {
      await del("venue/$1/order/$2", [order.venueId, order.id]).auth(user);
    } catch (e) {
      // If e is not false, then no error-catcher caught the error and
      // you might want to take care of it
      e && alert(e);
    }
    updateOrders(() => setLoading(false));
  };

  return (
    <div className="bg-white border border-gray-300 rounded-md overflow-hidden mt-4">
      <div className="px-4 bg-white py-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Refunds</h3>
      </div>
      <div className="p-4">
        {loading || ordersLoading ? (
          <DuActivityIndicator local />
        ) : orderWasRefunded(order) ? (
          "Bereits angefordert"
        ) : (
          <DuButton onClick={requestRefund}>Refund anfordern</DuButton>
        )}
      </div>
    </div>
  );
};
Refund.propTypes = {
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateOrders: PropTypes.func.isRequired,
  loading: PropTypes.object,
};

export default connect(({ user, loading }) => ({ user, loading }), {
  updateOrders,
})(Refund);
