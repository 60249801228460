import "@apparts/web-components/style.css";
import "./App.css";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContentPane } from "view-shells/AppContentPane/AppContentPane";

function App() {
  return (
    <Router>
      {/*debug*/}
      {/*<nav className="fixed bottom-0 w-64 float-right m-10 opacity-50 r-0 b-0 bg-white p-2">*/}
      {/*  <Link to="/dineup-landing">Landing</Link> |*/}
      {/*  <Link to="/venue-welcome">Venue</Link>|*/}
      {/*  <Link to="/order-process">Main</Link>|*/}
      {/*  /!*<Link to="/order-process">Beverage</Link>|*!/*/}
      {/*  /!*<Link to="/menu-index-step">Dessert</Link>|*!/*/}
      {/*  <Link to="/menu-item-details">Itemdetails</Link>|*/}
      {/*  <Link to="/basket">Basket</Link>|*/}
      {/*  <Link to="/order-confirmation">Orderconfirmation</Link>*/}
      {/*</nav>*/}

      <AppContentPane />
    </Router>
  );
}

export default App;
