const statusColor = (status) =>
  ({
    ["payment succeeded"]:
      "bg-red-100 text-red-800 hover:bg-red-300 hover:text-red-900",
    ["payment aborted"]:
      "bg-gray-100 text-gray-800 hover:bg-gray-300 hover:text-gray-900",
    ["order in process"]:
      "bg-yellow-100 text-yellow-800 hover:bg-yellow-300 hover:text-yellow-900",
    ["order done"]:
      "bg-green-100 text-green-800 hover:bg-green-300 hover:text-green-900",
  }[status]);

const getVenueStati = (
  order = { states: [] },
  venue = { allowedOrderStates: [] }
) =>
  order.states.filter(
    ({ state }) =>
      venue.allowedOrderStates.filter(
        ({ name, venue }) => name === state && !!venue
      ).length > 0
  );

const orderWasRefunded = (order = { states: [] }) =>
  order.states.filter(({ state }) => state === "payment refunded").length > 0;

export { statusColor, getVenueStati, orderWasRefunded };
