import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Prompt } from "react-router-dom";

import { getVenueId } from "lenses/venueId";
import { get, post } from "util/api";
import { connect } from "react-redux";
import { DuActivityIndicator } from "components/DuActivityIndicator/DuActivityIndicator";
import { DuToggle } from "components/DuToggle/DuToggle";
import { DuButton } from "components/DuButton/DuButton";

import { flatten } from "ramda";
import { map } from "@dineup/businesslogic/build/src";
const { mapMenu, mapStep, mapSection } = map;
const Menu = ({ user }) => {
  const [menu, setMenu] = useState(null);

  const loadMenu = async () => {
    if (user.apiToken) {
      try {
        const [response] = await get("venue/$1/menu", [getVenueId(user)])
          .query({ order: [{ key: "created", dir: "DESC" }], limit: 1 })
          .auth(user)
          .on(
            {
              status: 403,
              error: "You don't have the rights to retrieve this.",
            },
            () => {
              alert("You can not access this data. Please contact support.");
            }
          );
        setMenu(response.menu);
      } catch (e) {
        e && alert(e);
      }
    }
  };

  const saveMenu = async () => {
    setLoading(true);
    try {
      await post("venue/$1/menu", [getVenueId(user)])
        .data({ menu })
        .auth(user);
      await loadMenu();
      setLoading(false);
      setMenuChanged(false);
    } catch (e) {
      e && alert(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMenu();
  }, [user]);

  const [menuChanged, setMenuChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const setDishEnabled = (editDish, val) => {
    setMenu(
      mapMenu(menu, (step, stepIdx) =>
        mapStep(step, (subsection, sectionIdx) =>
          mapSection(subsection, (dish, dishIdx) =>
            editDish.stepIdx === stepIdx &&
            editDish.sectionIdx === sectionIdx &&
            editDish.dishIdx === dishIdx
              ? { ...dish, disabled: !val }
              : dish
          )
        )
      )
    );
    setMenuChanged(true);
  };

  if (!menu) {
    return <DuActivityIndicator />;
  }

  const dishes = flatten(
    menu.steps.map(({ sections }, stepIdx) =>
      sections.map(({ items }, sectionIdx) =>
        items.map((dish, dishIdx) => ({
          ...dish,
          stepIdx,
          sectionIdx,
          dishIdx,
        }))
      )
    )
  );
  return (
    <>
      <div className="flex justify-end">
        <DuButton
          loading={loading}
          disabled={!menuChanged}
          className="-mt-4 mb-4"
          label="Speichern"
          onClick={saveMenu}
        />
      </div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gericht
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Verfügbar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dishes.map(({ name, disabled, ...rest }, i) => (
                    <tr
                      key={
                        rest.dishIdx +
                        "_" +
                        rest.sectionIdx +
                        "_" +
                        rest.stepIdx
                      }
                      className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <DuToggle
                          setEnabled={(v) => setDishEnabled(rest, v)}
                          enabled={!disabled}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Prompt
        when={menuChanged}
        message="Du hast ungespeicherte Änderungen. Willst du die Seite wirklich verlassen?"
      />
    </>
  );
};
Menu.propTypes = {
  user: PropTypes.object,
};
export default connect(({ user }) => ({ user }), {})(Menu);
