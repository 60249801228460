import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { loadOrders } from "redux/orders/actions";
import { loadVenue } from "redux/venue/actions";

import { DuActivityIndicator } from "components/DuActivityIndicator/DuActivityIndicator";
import { DuButton } from "components/DuButton/DuButton";
import { ChevronRightIcon } from "@heroicons/react/solid";
import OrderSlideOver from "./OrderSlideOver";

import useLogin from "util/hooks/useLogin";

import { dateToText } from "util/index.js";
import { statusColor, getVenueStati } from "lenses/status";

const Orders = ({ user, orders, loadOrders, loadVenue, active, venue }) => {
  const history = useHistory();
  useLogin(user, history);

  const [page, setPage] = useState(0);

  const nextPage = () => {
    loadOrders(page + 1);
    setPage(page + 1);
  };
  const previousPage = () => {
    const newPage = Math.max(0, page - 1);
    loadOrders(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      loadOrders(page);
    }, 1000 * 30);
    return () => clearTimeout(timeout);
  });

  useEffect(() => {
    if (user.apiToken) {
      loadOrders(0);
      loadVenue();
    }
  }, [user]);

  const [showDetails, setShowDetails] = useState(null);
  const onDetails = (order) => {
    setShowDetails(order.id);
  };

  if (!orders) {
    return <DuActivityIndicator />;
  }

  let visibleOrders = orders;
  if (active) {
    visibleOrders = [
      ...orders.filter(
        ({ states }) =>
          states.filter(({ state }) => state === "payment succeeded").length >
            0 && states.filter(({ state }) => state === "order done").length < 1
      ),
    ];

    visibleOrders.sort(({ created: c1 }, { created: c2 }) => c1 - c2);
  }

  return (
    <div className="-mt-6">
      <div className="mb-4 flex items-center justify-center">
        <DuButton onClick={previousPage}>Vorherige Seite</DuButton>
        <p className="px-10">Seite {page + 1}</p>
        <DuButton onClick={nextPage}>Nächste Seite</DuButton>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="">
          {visibleOrders.map((order, i) => (
            <li key={order.id}>
              <a
                onClick={() => onDetails(order)}
                className={
                  "block hover:bg-gray-200" +
                  (i % 2 === 0 ? "" : " bg-gray-100")
                }
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex flex-1 items-center justify-between">
                    <p className="flex items-center text-black text-xl">
                      <b>{order.id}</b>
                      {"\u00A0\u00A0\u00A0"} {order.order.table}
                    </p>

                    <div className="ml-2 flex flex-1 justify-end">
                      {getVenueStati(order, venue).map(({ state, id }) => (
                        <p
                          key={id}
                          className={
                            "px-2 m-2 inline-flex text-xs leading-5 font-semibold rounded-full " +
                            statusColor(state)
                          }
                        >
                          {state}
                        </p>
                      ))}
                    </div>
                    <div className="ml-5 flex-shrink-0">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <p>
                        <b>{dateToText(order.created).split(",")[1]}</b>
                        {"\u00A0\u00A0"}
                        {dateToText(order.created).split(",")[0]}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <p>
                        {order.total / 100} {order.currency}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <OrderSlideOver
        order={orders.filter(({ id }) => id === showDetails)[0]}
        onClose={() => setShowDetails(null)}
      />
    </div>
  );
};

Orders.propTypes = {
  user: PropTypes.object,
  orders: PropTypes.array,
  loadOrders: PropTypes.func.isRequired,
  loadVenue: PropTypes.func.isRequired,
  active: PropTypes.bool,
  venue: PropTypes.object,
};

export default connect(
  ({ user, orders, venue }) => ({
    user,
    orders,
    venue,
  }),
  { loadOrders, loadVenue }
)(Orders);
