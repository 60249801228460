import React, { Fragment } from "react";
import { intersperse, flatten } from "ramda";
import PropTypes from "prop-types";

const Nbsp = () => "\u00A0\u00A0";

const OrderAsText = ({ order }) =>
  flatten(
    intersperse(
      <br />,
      order.orderAsText
        .replace("\n", "")
        .split("\n")
        .map((line) => intersperse(<Nbsp />, line.split("  ")))
    )
  ).map((element, i) => <Fragment key={i}>{element}</Fragment>);

OrderAsText.propTypes = {
  order: PropTypes.object.isRequired,
};

export default OrderAsText;
