import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import OrderAsText from "./OrderAsText";
import Refund from "./Refund";

import Status from "./Status";

import { dateToText } from "util/index.js";

const OrderSlideOver = ({ onClose, order }) => {
  const open = !!order;

  const headerVals = [];
  if (order) {
    headerVals.push({
      name: "Betrag",
      value: order.total / 100 + " " + order.currency,
    });
    headerVals.push({ name: "Vom", value: dateToText(order.created) });
    if (order.order && order.order.table) {
      headerVals.push({ name: "Tisch", value: order.order.table });
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={open}
          onClose={onClose}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-out duration-75"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in duration-75 "
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-2xl">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Bestellung
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => onClose(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      {order && (
                        <div className="absolute inset-0 px-4 sm:px-6">
                          <dl
                            className={
                              "my-4 mt-5 grid grid-cols-1 gap-5 sm:grid-cols-" +
                              headerVals.length
                            }
                          >
                            {headerVals.map(({ name, value }) => (
                              <div
                                key={name}
                                className="px-4 py-5 bg-white  border border-gray-300 rounded-lg overflow-hidden sm:p-6"
                              >
                                <dt className="text-sm font-medium text-gray-500">
                                  {name}
                                </dt>
                                <dd className="mt-1 text-l font-semibold text-gray-900">
                                  {value}
                                </dd>
                              </div>
                            ))}
                          </dl>

                          <div className="my-4 bg-white border border-gray-300 overflow-hidden rounded-md">
                            <div className="px-4 bg-white py-5 border-b border-gray-200">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Bestellung
                              </h3>
                            </div>
                            <div className="p-4">
                              <code>
                                <OrderAsText order={order} />
                              </code>
                            </div>
                          </div>
                          <Status order={order} />
                          <Refund order={order} />
                          <div className="pb-4" />
                        </div>
                      )}
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
OrderSlideOver.propTypes = {
  onClose: PropTypes.func.isRequired,
  order: PropTypes.object,
};

export default OrderSlideOver;
