import React from "react";
import { useRequestPasswordReset } from "@apparts/login";
import * as components from "@apparts/web-components";

import * as api from "util/api";

const RequestPWResetScreen = () => {
  const RequestPWReset = useRequestPasswordReset({
    api,
    apiPrefix: "venue/user",
    components: components,
  });
  return (
    <div
      style={{
        maxWidth: 600,
        margin: "auto",
        padding: 20,
        marginTop: 100,
      }}
    >
      <RequestPWReset />
    </div>
  );
};

export default RequestPWResetScreen;
